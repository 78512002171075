<template>
  <div class="base-content">
    <form-frame
      :reset-visible="false"
      :submit-visible="false"
      @on-back = "handleBack"
    >
      <div slot="content">
        <query-frame
      :add-visible="false"
      :page-visible="false"
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="查询时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
      </div>
    </form-frame>
  </div>
</template>

<script>
import moment from 'moment';
import { vehicleDetail } from '@/api/vehicle';
import queryMixin from '@/mixin/queryMixin';
import FormFrame from '@/components/frame/FormFrame.vue';
import IDatePicker from '../../components/IDatePicker.vue';

export default {
  name: 'VehicleDetail',
  mixins: [queryMixin],
  components: {
    FormFrame,
    IDatePicker
  },
  props: {},
  data() {
    // const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const startDate = moment(new Date()).format('YYYY-MM-DD');
    const endDate = moment(new Date()).format('YYYY-MM-DD');
    return {
      query: {
        shopId: null,
        kartCode: null,
        duration: [startDate, endDate],
      },
      // 默认展示表格
      columns: [
        {
          title: '车辆名称',
          dataIndex: 'kartName',
          key: 'kartName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '发车次数',
          dataIndex: 'totalCount',
          key: ' totalCount',
          ellipsis: true,
          width: 200,
        },
        {
          title: '总计金额（元）',
          dataIndex: 'totalPrice',
          key: 'totalPrice',
          ellipsis: true,
        },
        {
          title: '会员金额（元）',
          dataIndex: 'memberPrice',
          key: 'memberPrice',
          ellipsis: true,
        },
        {
          title: '散客金额（元）',
          dataIndex: 'noMemberPrice',
          key: 'noMemberPrice',
          ellipsis: true,
        },
      ],
      dataList: []
    };
  },
  computed: {
    kartId() {
      return this.$route.params.kartId;
    },
    kartName() {
      return this.$route.params.kartName;
    },
    shopId() {
      return this.$route.params.shopId;
    },
  },
  watch: {},
  created() {
    console.log(this.kartId);
    console.log(this.kartName);
    console.log(this.shopId);
    // this.handleQuery();
    this.getData();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      const params = {
        kartId: this.kartId,
        startDate: this.query.duration[0],
        endDate: this.query.duration[1]
      };
      this.dataList = [];
      vehicleDetail(params).then((data) => {
        // console.log(data)
        const tempList = [];
        if (data) {
          data.kartName = this.kartName;
          tempList.push(data);
        }
        this.dataList = tempList;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'vehicleAdd'
      });
    },

    handleInfo(row) {
      this.$router.push({
        name: 'vehicleInfo',
        params: {
          id: row.kartId
        }
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'vehicleEdit',
        params: {
          id: row.kartId
        }
      });
    },
    handleDetail(row) {
      this.$router.push({
        name: 'vehicleDetail',
        params: {
          id: row.kartId
        }
      });
    },
    handleBack() {
      const backShopId = this.shopId;
      console.log(`backshopId : ${backShopId}`);
      this.$router.push({
        name: 'vehicleList',
        params: {
          shopId: backShopId
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>

</style>
